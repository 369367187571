/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "components/Cards/CounterCards/DefaultCounterCard";
import { useTranslation } from "react-i18next";

import { useDatabase, useDatabaseObjectData } from "reactfire";
import { ref } from "firebase/database";

function Counters() {
  const { t } = useTranslation();
  const database = useDatabase();
  const counterRef = ref(database, "stats/totals");
  const { status, data } = useDatabaseObjectData(counterRef);
  if (status === "loading") {
    return <span>loading...</span>;
  }

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }} justifyContent="center">
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={data?.casualties}
              //suffix="+"
              title={t("number of casualties")}
              // description=""
              sx={{ backgroundColor: "gray" }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            {/*<Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />*/}
            <DefaultCounterCard
              count={data?.children_casualties}
              title={t("number of children killed")}
            />
            {/*<Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />*/}
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard count={data?.detainees} title={t("number of detainees")} />
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }} justifyContent="center">
          <Grid item xs={12} md={4}>
            <DefaultCounterCard
              count={data?.sentenced_to_death}
              //suffix="+"
              title={t("sentenced to death")}
              // description=""
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DefaultCounterCard count={data?.executions} title={t("number of executions")} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
