/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

// Pages
import AboutUs from "layouts/pages/company/about-us";

import { useTranslation } from "react-i18next";

const LanguageItem = (props) => {
  const { lng } = props;
  const { t, i18n } = useTranslation();
  const isSelected = i18n.language === lng;
  var lngText;
  switch (lng) {
    case "en":
      lngText = t("english", { lng: "en" });
      break;
    case "fa":
      lngText = t("persian", { lng: "fa" });
      break;
    case "de":
      lngText = t("german", { lng: "de" });
      break;
  }
  return (
    <MKBox>
      <MKButton
        variant="text"
        color="secondary"
        onClick={() => i18n.changeLanguage(lng)}
        sx={isSelected && { backgroundColor: "lightgray" }}
      >
        {lngText}
      </MKButton>
    </MKBox>
  );
};

// Sections
// Note: Route names will be translated at the render time and the names will be used as i18n key.
const routes = [
  {
    name: "language",
    icon: <Icon>language</Icon>,
    collapse: [
      {
        name: <LanguageItem lng="fa" />,
      },
      {
        name: <LanguageItem lng="en" />,
      },
      {
        name: <LanguageItem lng="de" />,
      },
    ],
  },
];

export default routes;
