// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getDatabase } from "firebase/database"; // Firebase v9+

import ReactGA from "react-ga4";
ReactGA.initialize("G-02DYBFW8NC", {
  debug: true,
  gaOptions: { siteSpeedSampleRate: 100 },
});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBO9X-rUyufxTa0c4R0zUC6U6AfZgfQE-A",
  authDomain: "iri-counter.firebaseapp.com",
  databaseURL: "https://iri-counter-default-rtdb.firebaseio.com",
  projectId: "iri-counter",
  storageBucket: "iri-counter.appspot.com",
  messagingSenderId: "166831663452",
  appId: "1:166831663452:web:94802cdae432c33f9db999",
  measurementId: "G-02DYBFW8NC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const database = getDatabase(app);

export { database, firebaseConfig };
